

// gnav
 	
// $(function(){ 
// 	if(window.matchMedia('(max-width:767px)').matches) {
// 		$(function(){
// 			$(document).on("click", "#menu", function() {
// 				$("body").toggleClass("no-scroll");
// 				$("#menu").toggleClass("active");
// 				$("#spnav").slideToggle(100);
// 				//$("#spnav").css("display","flex");
// 				$(function(){
// 					if($(".no-scroll").length) { 
// 					  $("body").css('overflow','hidden');
// 					} else {
// 					  $("body").css('overflow','auto');
// 					}
// 				  });
// 				return false;
				
// 			});
			
// 			// $(document).click(function(event) {
// 			// 	if(!$(event.target).closest('.header').length) {
// 			// 		$("#menu").removeClass("active");
// 			// 		$("#spnav").slideUp();
// 			// 	}
// 			// });
// 			// $("#menu").on(_touch,function() {
// 			// 	event.stopPropagation();
// 			// });
// 		})
// 	}
// });

//sticky header1
// $(function() {
// 	var $win = $(window),
// 		$main = $('#main'),
// 		$nav = $('.gnav__item'),
// 		navHeight = $nav.outerHeight(),
// 		navPos = $nav.offset().top,
// 		onClass = 'on';
  
// 	$win.on('load scroll', function() {
// 	  var value = $(this).scrollTop();
// 	  if ( value > navPos ) {
// 		$nav.addClass(onClass);
// 		$('#mainnav').css('margin-top', '50px');
// 		$('#spnav').css('top', '50px');
// 	  } else {
// 		$nav.removeClass(onClass);
// 		//$main.css('margin-top', '0');
// 		$('#mainnav').css('margin-top', '0');
// 		$('#spnav').css('top', '111px');
// 	  }
// 	});
// });

//sticky header2
// $(function() {
// 	var $win = $(window),
// 		$main = $('#main'),
// 		$cta = $('#cta'),
// 		ctaHeight = $cta.outerHeight(),
// 		ctaPos = $cta.offset().top + 130,
// 		fixedClass = 'sticky';
  
// 	$win.on('load scroll', function() {
// 	  var value = $(this).scrollTop();
// 	  if ( value > ctaPos ) {
// 		$cta.addClass(fixedClass);
// 		//$('#cta').addClass('sticky');
// 	  } else {
// 		$cta.removeClass(fixedClass);
// 		//$main.css('margin-top', '0');
// 		//$('#cta').removeClass('sticky');
// 	  }
// 	});
// });

//scroll
$(function(){
	$('a[href^="#"]').click(function(){
		var speed = 500;
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$("html, body").animate({scrollTop:position}, speed, "swing");
		return false;
	});
});

//news tikcer
$(function(){
	$('#ticker').webTicker({
		hoverpause: false
	});
	$('#ticker-article').webTicker({
		hoverpause: false,
		startEmpty: false,
		duplicate: true
	});
	$('#ticker-footer').webTicker({
		hoverpause: false,
		startEmpty: false,
		duplicate: true
	});
	$('#ticker-footer02').webTicker({
		hoverpause: false,
		startEmpty: false,
		duplicate: true
	});
});


//slider

$(document).ready(function(){
	$('#slide').slick({
		infinite: true,
		autoplay: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		arrows: false,
		dots: true,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	});

	$('#gallery').slick({
		infinite: true,
		autoplay: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		centerMode: true,
		arrows: false,
		dots: false,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				// autoplaySpeed: 0,
				// speed:3000,
				// useTransform: false
			  }
			}
		  ]
	});

	$('#related').slick({
		infinite: true,
		autoplay: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		centerMode: true,
		arrows: false,
		dots: false,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	});

	// $('#ticker-article').slick({
	// 	infinite: true,
	// 	autoplay: true,
	// 	// slidesToShow: 3,
	// 	// slidesToScroll: 1,
	// 	//centerMode: true,
	// 	arrows: false,
	// 	dots: false,
	// 	autoplaySpeed: 0,
	// 	speed:3000,
	// 	useTransform: false,
	// 	variableWidth: true
	// });
	// $('#ticker-footer').slick({
	// 	infinite: true,
	// 	autoplay: true,
	// 	// slidesToShow: 3,
	// 	// slidesToScroll: 1,
	// 	//centerMode: true,
	// 	arrows: false,
	// 	dots: false,
	// 	autoplaySpeed: 0,
	// 	speed:3000,
	// 	useTransform: false,
	// 	variableWidth: true
	// });
	// $('#ticker-footer02').slick({
	// 	infinite: true,
	// 	autoplay: true,
	// 	// slidesToShow: 3,
	// 	// slidesToScroll: 1,
	// 	//centerMode: true,
	// 	arrows: false,
	// 	dots: false,
	// 	autoplaySpeed: 0,
	// 	speed:3000,
	// 	useTransform: false,
	// 	variableWidth: true
	// });
});

//ランダム背景色

$(function () {
	var num1 = Math.ceil(5 * Math.random());
	$('.related__article:first-child .related__txt').addClass('background' + num1);
	var num2 = Math.ceil(5 * Math.random());
	$('.related__article:nth-child(1) .related__txt').addClass('background' + num2);
	var num3 = Math.ceil(5 * Math.random());
	$('.related__article:nth-child(2) .related__txt').addClass('background' + num3);
	var num4 = Math.ceil(5 * Math.random());
	$('.related__article:nth-child(3) .related__txt').addClass('background' + num4);
	var num5 = Math.ceil(5 * Math.random());
	$('.related__article:nth-child(4) .related__txt').addClass('background' + num5);
	var num6 = Math.ceil(5 * Math.random());
	$('.related__article:nth-child(5) .related__txt').addClass('background' + num6);
	var num7 = Math.ceil(5 * Math.random());
	$('.related__article:nth-child(6) .related__txt').addClass('background' + num7);
	var num8 = Math.ceil(5 * Math.random());
	$('.related__article:nth-child(7) .related__txt').addClass('background' + num8);
});


//item

 	
// $(function(){ 
// 	$(function(){
// 		$(document).on("click", "#item-list__menu", function() {
// 			$(this).toggleClass("active");
// 			$(this).parents('#item-list').children('#item-nav-wrapper').slideToggle(200);
// 			return false;
// 		});
// 	})
// });